// Contains information about products (scale, display module, miniprogram), 2nd section on Emoware page
<template>
  <div>
    <!-- scale -->
    <div class="prod1Outer">
      <div class="prod1">
        <div class="sideImg" style="display:flex; justify-content: flex-start">
          <img src="../../assets/images/pc_web-08.png" alt="cloudia side" />
        </div>
        <div class="prod1Inner">
          <img :src="img1" alt="cloudia" />
          <div class="prod1Desc">
            <h1>{{ isEnglish ? h1.en : h1.cn }}</h1>
            <p>{{ isEnglish ? p1.en : p1.cn }}</p>
          </div>
        </div>
        <div class="sideImg" style="display:flex; justify-content: flex-end">
          <img src="../../assets/images/pc_web-10.png" alt="cloudia side" />
        </div>
      </div>
    </div>
    <!-- remote display module -->
    <div class="prod2Outer">
      <div class="prod2Inner">
        <div class="prod2Desc">
          <h1>{{ isEnglish ? h2.en : h2.cn }}</h1>
          <p>{{ isEnglish ? p2.en : p2.cn }}</p>
        </div>
        <img :src="img2" alt="remote display module" />
      </div>
    </div>
    <!-- wechat mini program -->
    <div class="prod3Outer">
      <img :src="img3" :srcset="img3srcset" alt="wechat mini program" />
      <div class="prod1Desc prod3Desc">
        <h1>{{ isEnglish ? h3.en : h3.cn }}</h1>
        <p>{{ isEnglish ? p3.en1 : p3.cn1 }}</p>
        <p>{{ isEnglish ? p3.en2 : p3.cn2 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      h1: { cn: "独特的云朵造型", en: "Cloud-shaped Design" },
      p1: {
        cn:
          "打破体重秤以往仅限于圆形和方形的传统概念，给人轻盈感。从人文关怀角度出发，遵循人体工程学、美学、心理学、家居、人工智能等原理开发软硬件设计。让产品更具人性化，更符合智能家居、物联网时代对智能产品的定义。",
        en:
          "Electronic scales do not have to be just squares or circles. At O!sheepps, measurement is a joyful experience. The key focus of our cloud-shaped design is not just visual, but psychological and aesthetic. The ultimate goal of our design is to make the electronic scale not just a standalone device of smart home; it is the start of a new wave in AIoT."
      },
      h2: { cn: "分离模块", en: "Remote Display Module " },
      p2: {
        cn:
          "友好贴心的分离屏显设计服务于一些特殊用户群体，如近视者，孕妇等，让他们可以轻松在目所能及处获取想要的数据信息。秤体与屏显分离式设计，不低头，向前看，轻松获取重要信息。",
        en:
          "The remote display module can be placed anywhere near the electronic scale, increasing convenience for the pregnant and short sighted who no longer need to check their weight by looking down."
      },
      h3: { cn: "微信小程序", en: "WeChat Mini Program" },
      p3: {
        cn1:
          "通过蓝牙链接小程序，设置目标体重，设备会比对实际体重与目标体重的差异。以小程序为智能家居互联载体，未来实现多维度情感交互以及商业化合作可能性。",
        en1:
          "Connect the mini program to the electronic scale via bluetooth, set up your personal health goals, and the scale will provide positive feedback about your personal goal and measurement results. Our design principles focus on multi-dimensional interaction between the user, the application, and the device. This mini program is also a platform that provides infinite possibilities with variety of partners.",
        cn2:
          "结合人体的年龄、身高、性别信息以及实际的体重信息，通过生物电阻抗分析算法可计算得出脂肪率、水分率、基础代谢、内脏脂肪等级、肌肉量、骨盐量、BMI、蛋白质、身体得分等数据。",
        en2:
          "Based on age, height, gender and weight, the electronic scale can generate body fat percentage, body water percentage, basal metabolic rate, visceral fat rate, muscle quality, bone mass, BMI (body mass index) and protein percentage based on a bioimpedance analysis algorithm. "
      },
      windowWidth: window.innerWidth,
      // change image url based on windowwidth is small
      img1:
        window.innerWidth < 580
          ? require("../../assets/images/cloudia-600w.png")
          : require("../../assets/images/cloudia-1200w.png"),
      img2:
        window.innerWidth < 580
          ? require("../../assets/images/remote-disp-600w.png")
          : require("../../assets/images/remote-disp-1000w.png"),
      img3:
        window.innerWidth < 580
          ? require("../../assets/images/phone-600w.png")
          : require("../../assets/images/phone-1824w.png"),
      img3srcset:
        window.innerWidth < 580
          ? require("../../assets/images/phone-600w.png") + " 600w"
          : require("../../assets/images/phone-1824w.png") +
            " 1824w, " +
            require("../../assets/images/phone-872w.png") +
            " 872w, " +
            require("../../assets/images/phone-1199w.png") +
            " 1199w"
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    }
  },
  watch: {
    // update image urls based on new window width
    windowWidth(newWidth) {
      if (newWidth < 580) {
        this.img1 = require("../../assets/images/cloudia-600w.png");
        this.img2 = require("../../assets/images/remote-disp-600w.png");
        this.img3 = require("../../assets/images/phone-600w.png");
        this.img3srcset =
          require("../../assets/images/phone-600w.png") + " 600w";
      } else {
        this.img1 = require("../../assets/images/cloudia-1200w.png");
        this.img2 = require("../../assets/images/remote-disp-1000w.png");
        this.img3 = require("../../assets/images/phone-1824w.png");
        this.img3srcset =
          require("../../assets/images/phone-1824w.png") +
          " 1824w, " +
          require("../../assets/images/phone-872w.png") +
          " 872w, " +
          require("../../assets/images/phone-1199w.png") +
          " 1199w";
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style scoped>
h1 {
  color: #424242;
  margin-bottom: 1.5rem;
}
p {
  color: #424242;
  font-size: 13px;
}

/* -------- PROD 1 ---------------------------------------- */
.prod1Outer {
  background-image: url("../../assets/images/productbg-1921w.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  padding-top: 300px;
  padding-bottom: 200px;
  min-height: calc(100vh - 100px);
  background-size: cover;
}
@media (min-width: 1500px), (max-width: 940px) {
  .prod1Outer {
    background-image: url("../../assets/images/productbg-4547w.png");
  }
}
.prod1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100vw;
  flex-wrap: wrap;
}
.prod1Inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.prod1Inner img {
  margin-right: 50px;
  min-width: 100px;
}
.prod1 img {
  height: 250px;
}
.prod1 .sideImg {
  flex-grow: 2;
}
.prod1 .sideImg img {
  height: 200px;
  margin-top: 20px;
}
.prod1Desc {
  flex-shrink: 2;
  max-width: 400px;
  text-align: left;
}

@media (min-width: 1400px) {
  .prod1 img {
    height: 350px;
  }
  .prod1 .sideImg img {
    height: 240px;
    margin-top: 20px;
  }
  .prod1Desc {
    max-width: 500px;
  }
  .prod1Inner img {
    margin-right: 100px;
  }
}
/* -------- PROD 2 ---------------------------------------- */
.prod2Outer {
  background-image: url("../../assets/images/pc_web-11.png");
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  padding-top: 200px;
  padding-bottom: 200px;
  min-height: calc(100vh - 100px);
  background-size: 200% auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prod2Outer img {
  width: 350px;
}
.prod2Inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.prod2Desc {
  text-align: left;
  max-width: 400px;
  margin-right: 50px;
}
@media (min-width: 1400px) {
  .prod2Outer img {
    max-width: 450px;
  }
  .prod2Desc {
    width: 500px;
    margin-right: 100px;
  }
}
/* -------- PROD 3 ---------------------------------------- */
.prod3Outer {
  padding-top: 200px;
  padding-bottom: 200px;
  min-height: calc(100vh - 100px);
  background-size: 1200px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.prod3Outer img {
  max-height: 80vh;
  max-width: 50vw;
}

@media (max-width: 940px) {
  h1 {
    font-size: 1.2rem;
  }
  p {
    font-size: 0.7rem;
  }
  .prod1 .sideImg img {
    display: none;
  }
  .prod1Desc {
    margin-right: 30px;
  }
  .prod1 img {
    width: 30vw;
    height: auto;
    margin-left: 30px;
    margin-right: 10px;
  }
  .prod2Desc {
    margin-left: 30px;
    margin-right: 0px;
  }
  .prod2Outer img {
    width: 40vw;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
  .prod1Outer {
    min-height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    background-position: center;
    background-size: 150% auto;
  }
  .prod2Outer {
    min-height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    background-size: 100% auto;
  }
  .prod3Outer {
    min-height: auto;
    padding-top: 20px;
    padding-bottom: 50px;
    background-size: 300px auto;
  }
}

@media (max-width: 580px) {
  .prod1Outer {
    background-image: none;
  }
  .prod1Inner {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .prod1Inner img {
    margin-right: 0px;
    min-width: 100%;
    margin-left: 0px;
  }
  .prod1Desc {
    margin-left: 30px;
    margin-right: 30px;
    width: 100%;
    padding: 0;
  }
  .prod2Outer {
    background-image: none;
  }
  .prod2Inner {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .prod2Outer img {
    max-height: 80vh;
    padding-bottom: 30px;
    width: 100%;
    margin: 0px;
  }
  .prod2Desc {
    margin-left: 30px;
    margin-right: 30px;
    padding: 0;
  }
  .prod3Outer {
    flex-wrap: wrap;
  }
  .prod3Outer img {
    max-height: none;
    max-width: calc(min(400px, 100vw));
    padding-bottom: 30px;
  }
  .prod3Desc {
    margin-left: 30px;
    margin-right: 30px;
    width: 100%;
    padding: 0;
  }
}
</style>
