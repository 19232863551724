// Banner to TMall store, bottom of Emoware page
<template>
  <div class="tmallBanner">
    <a
      :href="
        isMobile
          ? 'https://shop320849369.m.taobao.com'
          : 'http://osheepps.tmall.com/index.htm'
      "
      target="_blank"
      ><img src="../../assets/images/tmall.png" alt="tmall" />
      <hr />
      <div v-if="!isEnglish">
        <h3>商店目前在维护中</h3>
        <h3>您可以在天猫店购买到我们的正品产品!</h3>
        <h3>谢谢。</h3>
      </div>
      <div v-else>
        <h3>Our store is under maintenance.</h3>
        <h3>Please visit our TMall store to purchase!</h3>
        <h3>Thank you.</h3>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
  },
};
</script>

<style scoped>
.tmallBanner {
  width: 100%;
  background-color: #ff2c46;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tmallBanner img {
  width: 200px;
}

.tmallBanner hr {
  width: 60vw;
  border-top: 1px solid white;
  margin-top: 30px;
  margin-bottom: 30px;
}

.tmallBanner h3 {
  color: white;
  font-size: 1.5rem;
}

a:hover {
  text-decoration: none;
}

@media (max-width: 900px) {
  .tmallBanner img {
    width: 100px;
  }
  .tmallBanner h3 {
    font-size: 0.75rem;
    max-width: 90vw;
  }
  .tmallBanner {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 50px;
  }
}
</style>