import Vue from "vue";
import Vuex from "vuex";
import cookie from "vue-cookies";

Vue.use(Vuex);

// storing the global state variables
export default new Vuex.Store({
  state: {
    // Currently selected page component. Empty string if none is selected
    // Currently disabled. 
    // selectedScroll: (cookie.isKey("selectedScroll") && cookie.get("selectedScroll") != null)
    //   ? cookie.get("selectedScroll")
    //   : "",
    selectedScroll: "",
    // Language. If true, lang is english, else lang is chinese
    isEnglish: (cookie.isKey("lang") && cookie.get("lang") != null)
      ? (cookie.get("lang") == 'true')
      : navigator.language.includes("en"),
    // objects for the license and privacy policies. Kept in store so that they can be loaded when website is visited.
    license: null,
    privacy: null,
    brand: null,
    // true when cover is covering entire page
    // Currently disabled.
    isDown: true,
    // isDown: (cookie.isKey("isDown") && cookie.get("isDown") != null)
    //   ? (cookie.get("isDown") == 'true')
    //   : true,
  },
  mutations: {
    selectScroll: (state, payload) => {
      state.selectedScroll = payload;
      cookie.set("selectedScroll", payload);
    },
    switchLang: state => {
      state.isEnglish = !state.isEnglish;
      cookie.set("lang", state.isEnglish, "7d");
    },
    setLicense: (state, payload) => {
      state.license = payload;
    },
    setPrivacy: (state, payload) => {
      state.privacy = payload;
      console.log(state.privacy);
    },
    setBrand: (state, payload) => {
      state.brand = payload;
      console.log(state.brand);
    },
    setIsDown: (state, payload) => {
      state.isDown = payload;
      cookie.set("isDown", payload, 600);
    }
  },
  getters: {
    selectedScroll: state => {
      console.log(state.selectedScroll);
      return state.selectedScroll;
    },
    isEnglish: state => {
      return state.isEnglish;
    },
    isDown: state => {
      return state.isDown;
    },
  },
  actions: {},
  modules: {}
});
