// Outer class for all things Web. Contains logo, cover, scroll, and footer
<template>
  <div>
    <!-- logo, changes based on width -->
    <h3 class="logo" @click="goUp">
      <i
        v-if="!isDown && windowWidth < 580"
        class="icon-logo-o"
        @click="goUp"
      />
      <i v-else class="icon-logo-text" />
    </h3>

    <!-- Cover, containing Nav -->
    <cover class="outer-cover" @goDown="goDown" @goUp="goUp"></cover>

    <div class="outer-scroll-content">
      <!-- Page selected -->
      <transition name="slide">
        <div v-if="selectedScroll != ''">
          <component :is="selectedScroll"></component>
        </div>
      </transition>

      <!-- Fotoer -->
      <app-footer
        :allInactive="allFooterInactive"
        @goDown="goDown"
        @goUp="goUp"
        @selectedFooter="footerSelected"
      ></app-footer>
    </div>
  </div>
</template>

<script>
import Cover from "./components/Cover.vue";
import AboutUs from "./components/AboutUs.vue";
import Emoware from "./components/EmowareComponents/Emoware.vue";
import Shop from "./components/Shop.vue";
import Footer from "./components/Footer.vue";

import $ from "jquery";

export default {
  name: "App",
  data() {
    return {
      windowHeight: document.documentElement.clientHeight,
      windowWidth: document.documentElement.clientWidth,
      coverTop: 0,
      allFooterInactive: true,
    };
  },
  computed: {
    selectedScroll() {
      return this.$store.getters.selectedScroll;
    },
    isDown() {
      return this.$store.getters.isDown;
    },
  },
  methods: {
    // Moves cover. downHeight is eventual distance from bottom to cover bottom
    // wantAnimate indicates whether the move should be animated
    goDown(downHeight, wantAnimate) {
      this.$store.commit("setIsDown", false);

      // calculate speed
      let scrollDist = this.coverTop + downHeight;

      this.coverTop = -downHeight;

      // cover moves
      $(".outer-cover").animate(
        { top: -downHeight },
        wantAnimate ? this.speed(scrollDist) : 0
      );

      // logo moves
      if (window.innerWidth > 900) {
        $(".logo").animate({ top: 15 });
      } else {
        $(".logo").animate({ top: 5 });
      }

      // scroll content moves
      $(".outer-scroll-content").animate(
        { top: document.documentElement.clientHeight - downHeight },
        wantAnimate ? this.speed(scrollDist) : 0
      );
    },

    // Returns cover to position covering entire browser
    goUp() {
      this.$store.commit("setIsDown", true);
      let h = this.coverTop;
      this.coverTop = 0;
      $(".outer-cover").animate({ top: 0 }, this.speed(h));
      $(".logo").animate({ top: 40 });
      $(".outer-scroll-content").animate(
        { top: document.documentElement.clientHeight },
        this.speed(h)
      );
      $("html,body").animate({ scrollTop: 0 });
      this.$store.commit("selectScroll", "");
      this.allFooterInactive = true;
    },
    // calculate speed for animation based on the distance
    speed(height) {
      return Math.abs(height * 1) + 200;
    },
    onResize() {
      this.windowHeight = document.documentElement.clientHeight;
      this.windowWidth = document.documentElement.clientWidth;
    },
    footerSelected() {
      this.allFooterInactive = false;
    },
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      if (!this.isDown) {
        const x = this.coverTop + oldHeight;
        this.goDown(newHeight - x, false);
      }
      $(".cover-container").height(window.innerHeight);
    },
    selectedScroll() {
      this.allFooterInactive = true;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      // fix nav shrinking bug on about us page
      if (window.innerWidth > 650) {
        $(".cover-container").height(window.innerHeight);
      }
    });
    let HEADER_HEIGHT;
    if (this.selectedScroll != "") {
      if (window.innerWidth > 900) {
        HEADER_HEIGHT = 100;
      } else {
        HEADER_HEIGHT = 70;
      }
      this.goDown($(window).height() - HEADER_HEIGHT, false);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  components: {
    Cover,
    AboutUs,
    Emoware,
    Shop,
    "app-footer": Footer,
  },
};
</script>

<style scoped>
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "yang" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-logo:before {
  content: "\e903";
}
.icon-search:before {
  content: "\e900";
  color: #315043;
}
.icon-logo-text:before {
  content: "\e901";
}
.icon-logo-o:before {
  content: "\e902";
}
.icon-o:before {
  content: "\e902";
}
.logo {
  font-size: 80px;
  color: #fff;
  position: fixed;
  top: 40px;
  right: 60px;
  z-index: 300;
  font-family: "BrownRegular";
  min-width: 40px;
}
.outer-scroll-content {
  position: absolute;
  width: 100%;
  top: 100%;
}
@media (max-width: 900px) {
  .logo {
    font-size: 50px;
    position: fixed;
    right: 20px;
  }
  .icon-logo-o {
    font-size: 30px;
  }
}
.slide-enter-active {
  -moz-transition-duration: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 3s;
  -webkit-transition-duration: 3s;
  -o-transition-duration: 3s;
  transition-duration: 3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 2000px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
