// Display products at the top of Emoware page for Web
<template>
  <div
    class="bannerOuter"
    :style="{ backgroundImage: 'url(' + activeProduct.bgimg + ')' }"
  >
    <div class="row pic-outer">
      <transition name="slide-fade" mode="out-in">
        <div :key="activeProduct.title" class="transition-pic">
          <div class="picture">
            <div class="cloud-image">
              <div class="main-cover cover">
                <div
                  class="eye"
                  :style="{ backgroundColor: activeProduct.eyeColor }"
                ></div>
                <div
                  class="eye left"
                  :style="{ backgroundColor: activeProduct.eyeColor }"
                ></div>
              </div>
              <div class="main-cover">
                <img
                  v-if="!activeProduct.hasButton"
                  id="product-image"
                  src="../../assets/images/cloudia-main.png"
                  alt="cloudia main"
                />
                <img
                  v-else
                  id="product-image"
                  style="opacity: 1"
                  src="../../assets/images/product-no-shadow.png"
                  alt="cloudia product"
                />
              </div>
            </div>
          </div>
          <div class="pic-bottom">
            <h3 :style="{ color: activeProduct.textColor }">
              {{
                isEnglish ? activeProduct.enSubtitle : activeProduct.cnSubtitle
              }}
            </h3>
            <div class="bannertext">
              <p
                :style="{ color: activeProduct.textColor }"
                v-html="isEnglish ? activeProduct.enText : activeProduct.cnText"
              ></p>
            </div>
          </div>
        </div>
      </transition>
      <div class="pic-bottom">
        <div class="row eye-toggle">
          <div
            v-for="(product, index) in products.filter(
              product => product.hasButton
            )"
            :key="index"
            class="button"
            :style="{ background: product.eyeColor }"
            :class="{ active: product.active }"
            @click="selectProduct(product)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          title: "Cloudia S1",
          textColor: "black",
          eyeColor: "",
          enSubtitle: '"Emotional Implant Technology"',
          cnSubtitle: '"Emotional Implant Technology"',
          enText: "",
          cnText: "",
          hasButton: false,
          active: true,
          bgimg: require("../../assets/images/bg-cloudia.png")
        },
        {
          title: "Boyfriend",
          textColor: "#6784a8",
          eyeColor: "#6784a8",
          enSubtitle: '"Boyfriend"',
          cnSubtitle: '"蓝朋友"',
          enText:
            "Your ideal boyfriend: not very talkative, but always gentle. <br>Whether you are large or small, this blue is reliable and inclusive.",
          cnText:
            "蓝朋友的蓝，就像理想中的男朋友。或许不怎么爱说话，却很温柔。<br> 无论你是胖是瘦，都蓝的很稳，爱的很包容。",
          hasButton: true,
          active: false,
          bgimg: require("../../assets/images/bg-boyfriend.png")
        },
        {
          title: "Pink Lady",
          textColor: "#dd8a88",
          eyeColor: "#dd8a88",
          enSubtitle: '"Pink Lady"',
          cnSubtitle: '"大蜜橘"',
          enText:
            "Choose her like you would a best friend. <br>Warm and caring, listen to her whisper secrets to you.",
          cnText:
            "选择大蜜橘就像选择自己的闺蜜。够温暖，也够贴心。<br> 快点凑近吧，看看大蜜橘和你说了哪些悄悄话。",
          hasButton: true,
          active: false,
          bgimg: require("../../assets/images/bg-pinklady.png")
        },
        {
          title: "Basic Edition",
          textColor: "#9c9ea0",
          eyeColor: "#9c9ea0",
          enSubtitle: '"Basic Edition"',
          cnSubtitle: '"执事灰"',
          enText:
            "A steward for your heath, reassuring you whenever you need it. <br>Calm, composed, and always honest.",
          cnText:
            "让灰执事作为你的健康管家，是件很安心事儿。<br> 低调、沉着、不慌张，也从不向你说谎。",
          hasButton: true,
          active: false,
          bgimg: require("../../assets/images/bg-basicedition.png")
        }
      ],
      selected: 0
    };
  },
  computed: {
    activeProduct() {
      for (let product of this.products) {
        if (product.active) {
          return product;
        }
      }
      return this.products[0];
    },
    isEnglish() {
      return this.$store.getters.isEnglish;
    }
  },
  methods: {
    selectProduct(product) {
      for (let p of this.products) {
        p == product && !p.active ? (p.active = true) : (p.active = false);
      }
    }
  }
};
</script>

<style scoped>
div {
  overflow: hidden;
}
.transition-pic {
  height: 660px;
}
.bannerOuter {
  padding-bottom: 10vh;
  font-family: "BrownRegular";
  background-position: center;
  background-size: calc(min(90%, 1000px)) auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  min-height: 800px;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
}
.row {
  width: 100%;
}
.pic-outer {
  position: absolute;
  top: 50vh;
  margin-top: -360px;
  display: flex;
  flex-direction: column;
}
h3 {
  font-size: 25px;
  z-index: 2;
}
.bannertext {
  margin-top: 30px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
p {
  font-size: 18px;
  max-width: 550px;
  width: 80%;
}
.button.active {
  width: 110px;
  transition: width 0.5s;
}
.button {
  width: 40px;
  height: 40px;
  border-radius: 60px;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
  transition: width 0.5s;
  z-index: 2;
}
.text {
  white-space: nowrap;
  display: none;
}
.text.active {
  display: block;
}
.eye-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 40px;
}
.eye-toggle .col {
  align-self: flex-start;
  width: auto;
}
.bluetooth {
  margin: 60px 0 40px 0;
}
.bluetooth img {
  width: 140px;
}
.price {
  margin: 40px 0 80px;
  font-size: 32px;
}

.picture {
  text-align: center;
  position: relative;
  margin: 0 auto;
  width: 0px;
  height: 530px;
  overflow: visible;
}
.picture img {
  width: 480px;
  opacity: 0.9;
}

.picture .main-cover {
  width: 800px;
  height: 500px;
  position: absolute;
  left: -390px;
  margin: 50px 0px;
}
.picture .main-cover.cover {
  position: absolute;
  left: -400px;
}
.picture .eye {
  width: 36px;
  height: 155px;
  border-radius: 100px;
  position: absolute;
  right: 310px;
  top: 160px;
  z-index: 30;
}
.picture .eye.left {
  right: 460px;
}
.pic-bottom {
  width: 100vw;
}

@media (max-height: 900px) and (min-width: 700px) {
  .transition-pic {
    height: 540px;
  }
  .picture {
    text-align: center;
    position: relative;
    margin: 0 auto;
    width: 0px;
    height: 424px;
    overflow: visible;
  }
  .pic-outer {
    margin-top: -310px;
  }
  .picture img {
    width: 384px;
    opacity: 0.9;
  }
  .picture .main-cover {
    width: 640px;
    height: 500px;
    position: absolute;
    left: -310px;
    margin: 40px 0px;
  }
  .picture .main-cover.cover {
    position: absolute;
    left: -320px;
  }
  .picture .eye {
    width: 29px;
    height: 124px;
    border-radius: 100px;
    position: absolute;
    right: 246px;
    top: 128px;
    z-index: 30;
  }
  .picture .eye.left {
    right: 360px;
  }
}
@media (max-width: 700px) {
  .transition-pic {
    height: 500px;
  }
  h3 {
    height: 2rem;
    font-size: 1.25rem;
  }
  .pic-outer {
    margin-top: -270px;
  }
  .picture {
    text-align: center;
    position: relative;
    margin: 0 auto;
    width: 200%;
    height: 300px;
    overflow: visible;
    left: calc(50vw);
  }
  .picture img {
    width: 250px;
    opacity: 0.9;
  }
  .picture .eye {
    width: 19px;
    height: 80px;
    border-radius: 100px;
    position: absolute;
    right: 346px;
    top: 85px;
    z-index: 30;
  }
  .picture .eye.left {
    right: 420px;
  }
  .button.active {
    width: 80px;
    transition: width 0.5s;
  }
  .button {
    width: 40px;
    height: 40px;
    border-radius: 60px;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    transition: width 0.5s;
  }
  .eye-toggle {
    margin-top: 30px;
  }
}
@media (max-height: 600px) and (min-width: 520px) {
  .pic-outer {
    margin-top: -200px;
  }
  .transition-pic {
    height: 600px;
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter {
  transform: translateY(30px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}
</style>