// Navigation bar for web, contains links to pages and up/down arrows
<template>
  <div>
    <nav class="nav">
      <!-- links -->
      <div class="nav-texts">
        <a
          v-for="(label, index) in labels"
          :key="index"
          class="nav-text nav-link"
          @click="selectScroll(label)"
          :class="{ active: label.isActive }"
          >{{ isEnglish ? label.enName : label.cnName }}
        </a>
      </div>
      <!-- up/down arrow -->
      <a v-if="isDown" class="nav-link down" @click="goDown"
        ><img src="../assets/images/arrow-down.png" width="60" alt="go down"
      /></a>
      <a v-else class="nav-link up" @click="goUp"
        ><img src="../assets/images/arrow-up.png" width="60" alt="go up"
      /></a>
      <!-- placeholder to center arrow (same width as links) -->
      <span class="nav-placeholder"></span>
    </nav>
  </div>
</template>

<script>
import $ from "jquery";

const FOOTER_HEIGHT = 102;
export default {
  data() {
    return {
      labels: [
        {
          enName: "emoware",
          cnName: "云朵随心秤",
          component: "emoware",
          isActive: false,
        },
        {
          enName: "shop",
          cnName: "购买",
          component: "shop",
          isActive: false,
        },
        {
          enName: "about us",
          cnName: "关于我们",
          component: "aboutUs",
          isActive: false,
        },
      ],
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
    selectedScroll() {
      return this.$store.getters.selectedScroll;
    },
    isDown() {
      return this.$store.getters.isDown;
    },
  },
  methods: {
    selectScroll(label) {
      for (let l of this.labels) {
        l.isActive = false;
      }
      if (this.selectedScroll == label.component) {
        // unselect link
        this.$store.commit("selectScroll", "");
        this.$emit("goUp");
        this.$store.commit("setIsDown", true);
      } else {
        // select link
        if (this.selectedScroll == "") {
          // need move cover up
          let HEADER_HEIGHT;
          if (window.innerWidth > 900) {
            HEADER_HEIGHT = 100;
          } else {
            HEADER_HEIGHT = 70;
          }
          this.$emit("goDown", $(window).height() - HEADER_HEIGHT, true);
          this.$store.commit("setIsDown", false);
        }

        label.isActive = true;
        this.$store.commit("selectScroll", label.component);
        $("html,body").animate({ scrollTop: 0 });
      }
    },
    goDown() {
      this.$store.commit("setIsDown", false);
      if (window.innerWidth <= 900) {
        this.$emit("goDown", 50, true);
      } else {
        this.$emit("goDown", FOOTER_HEIGHT, true);
      }
    },
    goUp() {
      this.$store.commit("setIsDown", true);
      this.$emit("goUp");
      this.$store.commit("selectScroll", "");
      for (let label of this.labels) {
        label.isActive = false;
      }
    },
  },
  watch: {
    // update active class of every label when store changes
    selectedScroll(newScroll) {
      if (newScroll == "") {
        for (let label of this.labels) {
          label.isActive = false;
        }
      }
    },
  },
  mounted() {
    for (let l of this.labels) {
      l.isActive = l.component == this.selectedScroll;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav.nav {
  position: absolute;
  bottom: 0;
  padding-left: 60px;
  padding-right: 60px;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
}
.nav-link {
  padding: 0;
}
nav.nav a {
  padding-bottom: 30px;
  color: #fff;
  border-bottom: 4px solid rgba(255, 255, 255, 0);
  cursor: pointer;
}
.nav-texts {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
}
.nav-text {
  margin-right: 100px;
}
nav.nav a.active {
  border-bottom: 4px solid #fff;
}
.nav-placeholder {
  width: 470px;
  flex-shrink: 3;
  min-width: 80px;
}
@media (max-width: 1200px) {
  .nav-text {
    margin-right: 50px;
  }
  .nav-placeholder {
    width: 350px;
  }
}
@media (max-width: 900px) {
  nav.nav {
    padding-left: 0px;
    padding-right: 0px;
  }
  .nav-text {
    margin-right: 30px;
    font-size: 0.8rem;
  }
  nav.nav a {
    padding-bottom: 20px;
  }
  .nav-placeholder {
    width: 250px;
  }
}
@media (max-width: 580px) {
  .down {
    display: none;
  }
  .up {
    display: none;
  }
  .nav-placeholder {
    display: none;
  }
}
</style>
