// Wrapper for Mobile view, containing Nav and MobileScroll
<template>
  <div class="mobileOuter">
    <!-- Nav -->
    <transition name="slide-horiz">
      <app-mobile-nav v-if="navSelected" @close="disappearNav" class="card">
      </app-mobile-nav>
    </transition>
    <!-- Page -->
    <app-mobile-scroll
      v-show="showScroll"
      @openNav="appearNav"
    ></app-mobile-scroll>
  </div>
</template>

<script>
import MobileNav from "./components/MobileNav.vue";
import MobileScroll from "./components/MobileScroll.vue";

export default {
  data() {
    return {
      navSelected: false,
      showScroll: true,
    };
  },
  components: {
    appMobileNav: MobileNav,
    appMobileScroll: MobileScroll,
  },
  methods: {
    disappearNav() {
      this.navSelected = false;
    },
    appearNav() {
      this.navSelected = true;
    },
  },
  watch: {
    navSelected(newVal) {
      if (!newVal) {
        this.showScroll = true;
      } else {
        setTimeout(() => {
          this.showScroll = false;
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
.mobileOuter {
  width: 100%;
  min-height: 101vh;
  display: inline-block;
}

/* Transition for Nav to slide in from left */
.card {
  position: absolute;
  z-index: 300;
  height: 101vh;
  width: 100vw;
}
.slide-horiz-enter-active {
  transition: all 0.5s ease;
}
.slide-horiz-leave-active {
  transition: all 0.5s ease;
}
.slide-horiz-enter {
  transform: translateX(-100vw);
}
.slide-horiz-leave-to {
  transform: translateX(-100vw);
}
</style>