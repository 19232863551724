// Container class for all of the components in Emoware page on Mobile
<template>
  <div class="outer">
    <mobile-banner></mobile-banner>
    <product></product>
    <prod-details></prod-details>
    <prod-maintenance></prod-maintenance>
    <tmall-banner></tmall-banner>
  </div>
</template>

<script>
import MobileBanner from "./MobileBanner.vue";
import Product from "./Product.vue";
import ProdDetails from "./ProdDetails.vue";
import ProdMaintenance from "./ProdMaintenance.vue";
import TmallBanner from "./TmallBanner.vue";

export default {
  components: {
    MobileBanner,
    Product,
    ProdDetails,
    ProdMaintenance,
    TmallBanner
  }
};
</script>

<style scoped>
.outer {
  color: black;
  background: white;
  min-height: calc(100vh - 210px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-x: hidden;
}
</style>