// Contact us, separate page on mobile, in footer on web
<template>
  <div
    class="contact-us-outer"
    v-bind:style="{
      'background-color': isMobile ? 'white' : '#f2f2f2',
    }"
  >
    <div class="contact-us-inner">
      <img
        src="../assets/images/qr-code.png"
        alt="Official WeChat account QR Code"
      />
      <p v-if="isMobile">{{ isEnglish ? p1.en : p1.cn }}</p>
      <p>{{ isEnglish ? p2.en : p2.cn }}</p>
      <p>{{ isEnglish ? p3.en : p3.cn }}</p>
    </div>
    <hr class="break" />
    <div class="contact-us-inner">
      <p>{{ isEnglish ? p4.en : p4.cn }}</p>
      <p>{{ isEnglish ? p5.en : p5.cn }}</p>
      <h3 style="color: #377267" v-html="email"></h3>
      <h3>
        <a style="color: #377267" href="tel:4006182777"
          ><nobr>400-618-2777</nobr></a
        >
      </h3>
    </div>
    <div v-if="!isMobile" style="height: 10vh"></div>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      p1: { en: "Long press to save the QR code", cn: "长按保存二维码" },
      p2: {
        en: "Follow the O!sheepps official WeChat account",
        cn: "请关注O!sheepps官方微信公众号",
      },
      p3: {
        en: "Keep up to date on the latest information!",
        cn: "时刻关注最新信息！",
      },
      p4: {
        en: "We would love to hear your valuable feedback!",
        cn: "如果您有宝贵的意见",
      },
      p5: {
        en: "Please contact us by email or phone:",
        cn: "可以通过电子邮件或电话与我们联系：",
      },
      email:
        "<a style='color: #377267' href='mailto:" +
        "info" +
        "@" +
        "o-sheepps.com" +
        "'>" +
        "info" +
        "@" +
        "o-sheepps.com" +
        "</a>",
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
  },
};
</script>

<style scoped>
.contact-us-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #377267;
  min-height: calc(100vh - 100px);
  padding-bottom: 50px;
}
.contact-us-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100px;
}
p {
  font-size: 0.75rem;
  margin-bottom: 0.2rem;
}
img {
  width: 100px;
  margin-bottom: 20px;
}
.break {
  width: 20px;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
h3 {
  margin-top: 0.5rem;
  font-size: 1.25rem;
}
</style>