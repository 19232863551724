// About Us page
<template>
  <div
    class="outer"
    :style="{
      'min-height': isMobile ? 'calc(100vh - 90px)' : 'calc(100vh - 202px)',
    }"
  >
    <!-- if web, add top gradient and extra padding -->
    <img
      style="width: 100%; position: absolute; top: 0px; left: 0px; z-index: 0"
      src="../assets/images/company profile-06.png"
      alt="top-gradient"
      v-if="!isMobile"
    />
    <div v-if="!isMobile" class="extra-padding" style="padding-top: 80px"></div>

    <div class="inner">
      <img
        class="greeting"
        src="../assets/images/company-profile-greeting.png"
        alt="We are O!sheepps"
      />
      <div class="text">
        <!-- broken up lines if chinese and large window -->
        <div v-if="!isEnglish && windowWidth > 1100">
          <p v-for="(text, index) in info" :key="index">{{ text.cn }}</p>
          <br /><br />
        </div>

        <!-- if english or small window, 3 paragraphs -->
        <div v-else>
          <p>
            {{ isEnglish ? info[0].en : info[0].cn }}
          </p>
          <p>
            {{
              isEnglish
                ? info[1].en + info[2].en + info[3].en + info[4].en
                : info[1].cn + info[2].cn + info[3].cn + info[4].cn
            }}
          </p>
          <p>
            {{ isEnglish ? info[5].en : info[5].cn }}
          </p>
          <br /><br />
        </div>
      </div>
    </div>
    <div class="o-logo">
      <img
        class="bottomLogo"
        src="../assets/images/company-profile-logo.png"
        alt="o!sheepps logo"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      info: [
        {
          cn: "O!sheepps坚持做有温度的科技产品, 探索和触发情感科技的思维模式。",
          en:
            "O!sheepps is dedicated to making high-tech products with a warm design, which led to the discovery of emotional design thinking. ",
        },
        {
          cn: "工业设计并不应该是冰冷的存在, 也可以拥有情感与温度的覆盖。",
          en:
            "We believe that industry design can combine people’s emotions with modern technologies. ",
        },
        {
          cn: "正是这种覆盖, 才会为使用者带来更多快乐。",
          en: "Because of the combination, users will gain more happiness. ",
        },
        {
          cn: "让温暖触及生活的每一处, 成为陪伴用户前进的同行者。",
          en:
            "We want to create warmth and become a companion accompanying users forward. ",
        },
        {
          cn:
            "主张“情感注入科技”的价值观, 做“改变用户生活方式”的智能产品, 创造智能家居 3.0 时代的“智能小家电”。",
          en:
            "Emotional design is O!sheepps's value proposition. We change people's life styles through emotional products, creating the age of smart home 3.0. ",
        },
        {
          cn: "基于这些想法, O!sheepps诞生了。",
          en: "Based on the values above, O!sheepps was born. ",
        },
      ],
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.extra-padding {
  display: none;
}
.greeting {
  max-width: 700px;
  width: 80vw;
  padding-top: 150px;
  padding-bottom: 100px;
}
.outer {
  color: black;
  background-image: url(../assets/images/company-profile-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inner {
  min-height: calc(100vh - 407px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.o-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100vw;
  padding-top: 80px;
  padding-bottom: 60px;
}
.text {
  color: #377267;
  padding-bottom: 50px;
  margin-right: 15vw;
  margin-left: 15vw;
  max-width: 800px;
}
.text p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.bottomLogo {
  width: 80px;
}
@media (max-width: 900px) {
  .outer {
    min-height: calc(100vh - 120px);
  }
  .inner {
    min-height: calc(100vh - 325px);
  }
}
@media (max-width: 775px) {
  .extra-padding {
    display: block;
  }
  .outer {
    display: flex;
    color: black;
    background: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url(../assets/images/company-profile-bg.png);
    background-position: bottom;
    background-size: calc(max(580px, 100%)) auto;
    background-repeat: no-repeat;
    min-height: calc(100vh - 100px);
  }
  .inner {
    min-height: calc(100vh - 240px);
  }
  .greeting {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .text {
    padding-bottom: 20px;
    margin-right: 10vw;
    margin-left: 10vw;
  }
  .text p {
    font-size: 0.8rem;
    text-align: left;
  }
  .bottomLogo {
    width: 40px;
    padding-top: 50px;
    padding-bottom: 30px;
    display: none;
  }
}
</style>