// Shop page for both web and mobile
<template>
  <div class="outer">
    <!-- if web, add top gradient and extra padding -->
    <img
      style="width: 100%; position: absolute; top: 0px; left: 0px; z-index: 0"
      src="../assets/images/company profile-06.png"
      alt="top gradient"
      v-if="!isMobile"
    />
    <div v-if="!isMobile" class="extra-padding" style="padding-top: 80px"></div>

    <!-- Shop -->
    <div class="flex-center-column">
      <h2 class="shoptitle" v-html="isEnglish ? h1.en : h1.cn"></h2>
      <div class="tmall">
        <a
          :href="
            isMobile
              ? 'https://shop320849369.m.taobao.com'
              : 'http://osheepps.tmall.com/index.htm'
          "
          target="_blank"
        >
          <div class="tmall-inner">
            <img src="../assets/images/tmall.png" alt="tmall" />
          </div>
        </a>
        <p>{{ isEnglish ? tmallSubtitle.en : tmallSubtitle.cn }}</p>
      </div>
    </div>

    <hr />

    <!-- Follow Us -->
    <div class="socialMedia">
      <h2>{{ isEnglish ? h2.en : h2.cn }}</h2>
      <div class="smbuttons" ref="button1">
        <a
          v-for="(button, index) in buttons"
          :key="index"
          @mouseover="button.ishover = !isMobile"
          @mouseleave="button.ishover = isMobile"
          @click="button.ishover = isMobile"
          :href="button.url"
          style="position: relative"
          :target="button.url == '#' ? '' : '_blank'"
        >
          <!-- tooltips -->
          <transition name="bounce">
            <span
              v-if="button.name == 'wechat' && button.ishover && !isMobile"
              class="tooltiptext"
              style="width: 300px; height: 120px"
            >
              <div class="flex-center-row">
                <img src="../assets/images/qr-code.png" />
                <div>
                  <p style="font-size: 1.3rem">
                    {{
                      isEnglish ? wechatPopup.title.en : wechatPopup.title.cn
                    }}
                  </p>
                  <p>
                    {{
                      isEnglish
                        ? wechatPopup.subtitle.en
                        : wechatPopup.subtitle.cn
                    }}
                  </p>
                </div>
              </div>
            </span>
            <span
              v-if="
                button.name == 'wechat mini program' &&
                button.ishover &&
                !isMobile
              "
              class="tooltiptext"
              style="width: 300px; height: 240px"
            >
              <div class="flex-center-row">
                <img src="../assets/images/miniprogram1.png" />
                <div>
                  <p style="font-size: 1.3rem">
                    {{
                      isEnglish
                        ? miniprogramPopup.program1.title.en
                        : miniprogramPopup.program1.title.cn
                    }}
                  </p>
                  <p>
                    {{
                      isEnglish
                        ? miniprogramPopup.program1.subtitle.en
                        : miniprogramPopup.program1.subtitle.cn
                    }}
                  </p>
                </div>
              </div>
              <hr />
              <div class="flex-center-row">
                <img src="../assets/images/miniprogram2.png" />
                <div>
                  <p style="font-size: 1.3rem">
                    {{
                      isEnglish
                        ? miniprogramPopup.program2.title.en
                        : miniprogramPopup.program2.title.cn
                    }}
                  </p>
                  <p>
                    {{
                      isEnglish
                        ? miniprogramPopup.program2.subtitle.en
                        : miniprogramPopup.program2.subtitle.cn
                    }}
                  </p>
                </div>
              </div>
            </span>
          </transition>
          <!-- buttons -->
          <img
            :src="button.ishover ? button.hover : button.reg"
            :alt="button.alt"
        /></a>
      </div>
      <p style="padding-bottom: 50px">
        {{ isEnglish ? socialMediaSubtitle.en : socialMediaSubtitle.cn }}
      </p>
    </div>

    <!-- mini program popup mobile -->
    <transition name="bounce">
      <span
        v-show="buttons[0].ishover && isMobile"
        class="popup"
        v-closable="{
          exclude: ['button', 'button1'],
          handler: 'onClose',
        }"
      >
        <div class="popup-inner">
          <button
            ref="button"
            class="x-button"
            @click="buttons[0].ishover = false"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-x"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
          <div
            class="popup-inner-inner"
            :style="{
              top: popupScrolledDown ? '-120px' : '0px',
            }"
            v-hammer:swipe.vertical="onSwipe"
          >
            <div
              class="flex-center-row popup-section"
              style="margin-right: -10px"
            >
              <img class="qr" src="../assets/images/miniprogram1.png" />
              <div>
                <p class="popup-header">
                  {{
                    isEnglish
                      ? miniprogramPopup.program1.title.en
                      : miniprogramPopup.program1.title.cn
                  }}
                </p>
                <p>
                  {{
                    isEnglish
                      ? miniprogramPopup.program1.subtitle.en
                      : miniprogramPopup.program1.subtitle.cn
                  }}
                </p>
              </div>
            </div>
            <!-- arrow button -->
            <div
              class="flex-center-row"
              style="height: 10px; justify-content: center"
              @click="
                popupScrolledDown = !popupScrolledDown;
                buttons[0].ishover = true;
              "
            >
              <img
                v-if="!popupScrolledDown"
                src="../assets/images/carousel-control-down.png"
                class="popup-arrow"
              />
              <img
                v-else
                src="../assets/images/carousel-control-up.png"
                class="popup-arrow"
              />
            </div>
            <!-- 2nd qr code -->
            <div class="flex-center-row popup-section">
              <img class="qr" src="../assets/images/miniprogram2.png" />
              <div>
                <p class="popup-header">
                  {{
                    isEnglish
                      ? miniprogramPopup.program2.title.en
                      : miniprogramPopup.program2.title.cn
                  }}
                </p>
                <p>
                  {{
                    isEnglish
                      ? miniprogramPopup.program2.subtitle.en
                      : miniprogramPopup.program2.subtitle.cn
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      popupScrolledDown: false,
      h1: {
        cn: "您可以在这里购买到我们的正品产品。",
        en: "Please visit our TMall store to purchase!",
      },
      tmallSubtitle: {
        cn: "O!sheepps天猫旗舰店",
        en: "O!sheepps TMall flagship store",
      },
      h2: {
        cn: "关注我们！",
        en: "Follow us! ",
      },
      socialMediaSubtitle: {
        cn: "您也可以通过以上平台了解到更多关于O!sheepps品牌与产品资讯。",
        en: "Learn more about the O!sheeps brand and products.",
      },
      miniprogramPopup: {
        program1: {
          title: {
            cn: "羊哎科技生活馆",
            en: "O!sheepps shop",
          },
          subtitle: {
            cn: "O!sheepps官方小程序商城",
            en: "O!sheepps official WeChat Mini Program Shop",
          },
        },
        program2: {
          title: {
            cn: "羊哎fit",
            en: "O!sheepps fit",
          },
          subtitle: {
            cn: "O!sheepps官方小程序",
            en: "A WeChat Mini Program",
          },
        },
      },
      wechatPopup: {
        title: {
          cn: "羊哎科技",
          en: "O!sheepps",
        },
        subtitle: {
          cn: "O!sheepps官方微信公众号",
          en: "WeChat Official Account",
        },
      },
      buttons: [
        {
          name: "wechat mini program",
          reg: require("../assets/images/shop+social media-02.png"),
          hover: require("../assets/images/shop+social media-10.png"),
          ishover: false,
          url: "#",
          alt: "wechat mini program logo",
        },
        {
          name: "wechat",
          reg: require("../assets/images/shop+social media-03.png"),
          hover: require("../assets/images/shop+social media-11.png"),
          ishover: false,
          url: "https://weixin.qq.com/r/piqLkyrENXdKrW7w93_0",
          alt: "wechat logo",
        },
        {
          name: "weibo",
          reg: require("../assets/images/shop+social media-04.png"),
          hover: require("../assets/images/shop+social media-12.png"),
          ishover: false,
          url: "https://weibo.com/u/7440467971",
          alt: "weibo logo",
        },
        {
          name: "xiaohongshu",
          reg: require("../assets/images/shop+social media-05.png"),
          hover: require("../assets/images/shop+social media-13.png"),
          ishover: false,
          url: "#",
          alt: "xiaohongshu logo",
        },
        {
          name: "x",
          reg: require("../assets/images/shop+social media-06.png"),
          hover: require("../assets/images/shop+social media-14.png"),
          ishover: false,
          url: "#",
          alt: "x logo",
        },
        {
          name: "x",
          reg: require("../assets/images/shop+social media-07.png"),
          hover: require("../assets/images/shop+social media-15.png"),
          ishover: false,
          url: "#",
          alt: "x logo",
        },
        {
          name: "youtube",
          reg: require("../assets/images/shop+social media-08.png"),
          hover: require("../assets/images/shop+social media-16.png"),
          ishover: false,
          url: "#",
          alt: "youtube logo",
        },
        {
          name: "instagram",
          reg: require("../assets/images/shop+social media-09.png"),
          hover: require("../assets/images/shop+social media-17.png"),
          ishover: false,
          url: "https://www.instagram.com/osheepps_official/",
          alt: "instagram logo",
        },
      ],
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
  },
  methods: {
    onSwipe() {
      this.popupScrolledDown = !this.popupScrolledDown;
      this.buttons[0].ishover = true;
    },
    onClose() {
      this.buttons[0].ishover = false;
    },
  },
};
</script>

<style scoped>
.flex-center-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.extra-padding {
  display: none;
}
.shoptitle {
  padding-top: 150px;
  margin-right: 20px;
  margin-left: 20px;
  max-width: 800px;
}
.outer {
  color: black;
  min-height: calc(100vh - 202px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.tmall {
  width: 85vw;
  max-width: 800px;
}
.tmall-inner {
  margin-top: 50px;
  /* disabled */
  /* background-color: #727171; */
  /* enabled */
  background-color: #e71f39;
  border-radius: 50px;
  padding: 20px;
}
.tmall-inner:hover {
  background-color: #c51e34;
}
.tmall-inner img {
  max-width: 200px;
  width: 40%;
  height: auto;
}
.tmall p {
  color: #5d5e5e;
  margin-top: 10px;
}
.socialMedia img {
  max-width: 45px;
  margin: 15px;
  margin-top: 45px;
  margin-bottom: 45px;
}
.socialMedia p {
  font-size: 0.75rem;
  color: #377065;
}
.tooltiptext {
  background-color: white;
  color: #377065;
  text-align: left;
  padding: 15px;
  border-radius: 15px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  bottom: 50px;
  left: -26px;
  right: 0;
  margin: 0 auto;
}
.tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 20%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}
.tooltiptext img {
  min-width: 90px;
  padding: 0px;
  margin: 0px !important;
  margin-right: 15px !important;
}
.tooltiptext p,
.popup p {
  margin-bottom: 0;
}
.tooltiptext hr,
.popup hr {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}
hr {
  margin-top: 70px;
  margin-bottom: 70px;
  border-top: 1px solid #989999;
  width: 70%;
}
.popup {
  width: 80%;
  height: 150px;
  overflow: hidden;
  background-color: white;
  color: #377065;
  text-align: left;
  padding-left: 15px;
  border-radius: 15px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.popup-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.popup-inner-inner {
  height: 240px;
  width: calc(100% - 30px);
  position: absolute;
  top: 0px;
  transition: all 0.5s ease 0s;
  padding-top: 20px;
  padding-bottom: 20px;
}
.popup .qr {
  width: 90px;
  padding: 0px;
  margin: 0px !important;
  margin-right: 15px !important;
}
.popup-header {
  font-size: 1.2rem;
}
.popup-arrow {
  height: 10px;
  width: auto;
  padding-left: 10px;
  margin-right: 0px !important;
}
.popup-section {
  height: 110px;
}
.x-button {
  position: absolute;
  right: 0;
  top: 0px;
  background-color: transparent;
  border: none;
  padding: 10px;
}

@media (max-width: 900px) {
  .outer {
    min-height: calc(100vh - 100px);
  }
  hr {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media (max-width: 650px) {
  .extra-padding {
    display: block;
  }
  h2 {
    font-size: 1rem;
    max-width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
  }
  .shoptitle {
    padding-top: 0px;
  }
  p {
    font-size: 0.75rem;
  }
  .tmall {
    max-width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
  }
  .tmall-inner {
    margin-top: 20px;
    padding: 10px;
  }
  .socialMedia {
    max-width: 200px;
  }
  .socialMedia img {
    max-width: 30px;
    margin: 10px;
  }
  .smbuttons {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .tooltiptext {
    bottom: 40px;
    left: -40px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.25s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
