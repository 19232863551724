// Main container. Will decide Mobile or Web view, or Error
<template>
  <div id="app" :style="langStyle">
    <!-- Error if mobile and landscape -->
    <app-error
      v-if="mobileCheck"
      class="errorHide"
      :message="orientationError"
    ></app-error>
    <app-web v-if="!mobileCheck"></app-web>
    <app-mobile v-else class="errorUnhide"></app-mobile>
  </div>
</template>

<script>
import Web from "./Web.vue";
import Mobile from "./Mobile.vue";
import MyError from "./components/Error.vue";

export default {
  name: "App",
  data() {
    return {
      orientationError: {
        en: "Please switch to portrait mode!",
        cn: "请换成竖屏!",
      },
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
    langStyle() {
      if (this.isEnglish) {
        return {
          fontFamily: "BrownRegular",
        };
      } else {
        return {
          fontFamily: "SourceHanSansCN",
        };
      }
    },
    mobileCheck() {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
      // return window.innerWidth < 700;
    },
  },
  components: {
    appWeb: Web,
    appMobile: Mobile,
    appError: MyError,
  },
  mounted() {
    // loads and puts license and privacy objects in store
    this.$http.get("license.txt").then((response) => {
      this.$store.commit("setLicense", response.data);
    });
    this.$http.get("privacy.txt").then((response) => {
      this.$store.commit("setPrivacy", response.data);
    });
    this.$http.get("brand.txt").then((response) => {
      this.$store.commit("setBrand", response.data);
    });
  },
};

// save the cookie
window.onbeforeunload = function () {
  this.$store.commit("selectScroll", this.$store.getters.selectedScroll);
};
</script>

<style>
@font-face {
  font-family: "ArialRoundedBold";
  src: url("./font/ArialRoundedBold.otf");
  src: url("./font/ArialRoundedBold.eot");
  src: url("./font/ArialRoundedBold.woff") format("woff"),
    url("./font/ArialRoundedBold.woff2") format("woff2");
}
@font-face {
  font-family: "BrownRegular";
  src: url("./font/Brown-Regular.ttf");
  src: url("./font/Brown-Regular.eot");
  src: url("./font/Brown-Regular.woff") format("woff");
}
@font-face {
  font-family: "BrownBold";
  src: url("./font/Brown-Bold.ttf");
  src: url("./font/Brown-Bold.eot");
  src: url("./font/Brown-Bold.woff") format("woff");
}
@font-face {
  font-family: "SourceHanSansCN";
  src: url("./font/SourceHanSansSC-Regular.otf");
}

#app {
  font-family: "BrownRegular", "ArialRoundedBold", Helvetica, Arial,
    "sans-serif";
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  display: flex;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

div {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* remove scrollbar */
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

@font-face {
  font-family: "yang";
  src: url("./font/yang.ttf?3ze4ta") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* hides error normally, if landscape, shows error and hides content */
.errorHide {
  display: none;
}

@media (orientation: landscape) {
  .errorHide {
    display: flex;
  }
  .errorUnhide {
    display: none !important;
  }
}
</style>
