// Contains information about maintenance and warranty, 4th section on Emoware page
<template>
  <div class="maintenanceOuter">
    <!-- warranty header -->
    <div class="section row">
      <div class="image">
        <img src="../../assets/images/pc_web-15.png" alt="maintenance" />
      </div>
      <div class="title">
        <div class="title-inner">
          <h1>{{ isEnglish ? warrantyText.h1.en : warrantyText.h1.cn }}</h1>
          <div class="warranty"><p>全国联保</p></div>
        </div>
        <hr />
        <div class="subtitle">
          <p>
            {{
              isEnglish ? warrantyText.subtitle.en : warrantyText.subtitle.cn
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- warranty text -->
    <div class="text row">
      <div>
        <p v-html="isEnglish ? warrantyText.h2.en : warrantyText.h2.cn"></p>
        <p v-html="isEnglish ? warrantyText.text.en : warrantyText.text.cn"></p>
      </div>
    </div>

    <!-- maintenance header -->
    <div class="section row">
      <div class="image">
        <img src="../../assets/images/pc_web-16.png" alt="warranty" />
      </div>
      <div class="title">
        <div class="title-inner">
          <h1>
            {{ isEnglish ? maintenanceText.h1.en : maintenanceText.h1.cn }}
          </h1>
        </div>
        <hr />
        <div class="subtitle">
          <p>
            {{
              isEnglish
                ? maintenanceText.subtitle.en
                : maintenanceText.subtitle.cn
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- maintenance text -->
    <div class="text row">
      <p
        v-html="isEnglish ? maintenanceText.text.en : maintenanceText.text.cn"
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      warrantyText: {
        h1: { cn: "产品保修", en: "WARRANTY" },
        subtitle: {
          cn:
            "在使用产品过程中,如果设备出现非人为损坏的性能故障。自购买日起12个月内消费者享有免费保修服务。",
          en:
            "The manufacturer warrants this product to be free from defects in workmanship and materials, under normal residential use and conditions, for a period of one (1) year for the original purchase date. The manufacturer agrees, at its option during the warranty period, to repair without charge. Such repair is subject to verification of the defect or malfunction and proof of purchase as confirmed by showing the model number on original dated sales receipt.   ",
        },
        h2: {
          cn: "非保修条例：有下列情况之一者,消费者不能享受保修权益",
          en: "WARRANTY LIMITATIONS - This warranty does not include: ",
        },
        text: {
          cn:
            "*人为损坏*超过保修期*无保修卡及有效发票,但能够证明设备在保修期内的除外*保修卡与设备型号标识不符,或保修卡被涂改 <br>*SN条码被撕去,但可提供产品发票及保修卡除外*未按说明书要求使用、维护、保养而造成配件损坏 <br>*未经苏州好像羊智能科技有限公司授权,对设备擅自拆卸、维修、浸水或摔坏设备*不可抗力造成的损坏。 ",
          en:
            "*Any condition resulting from other than ordinary residential wear or any use for which the product was not intended <br>*Exceeded warranty period <br>*No valid purchase certification and invoice except for a valid proof of within warranty period <br>*The warranty card does not match the device model identification, or the warranty card has been altered <br>*No device serial number information except for valid invoice and warranty card are provided  <br>*Unauthorized disassemble, repair, flooding or damage to the device <br>*Damage caused by force ",
        },
      },
      maintenanceText: {
        h1: { cn: "产品保养", en: "MAINTENANCE" },
        subtitle: {
          cn:
            "请将产品放置于干燥、水平的地面进行使用。使用本产品时请远离水源、火源、漏电、任意污染源与杂物堆砌场所。",
          en:
            "Please place the product on a dry, flat surface for use. Please stay away from water, fire, leakage, any pollution sources and debris piled places. ",
        },
        text: {
          cn:
            "请保持本设备及其配件干燥,请勿使用微波炉或吹风机等外部加热设备对其进行干燥。<br> 请勿在温度过高或过低区域放置设备及其配件,否则可能导致本设备故障、着火或爆。 <br>请勿使本设备及其配件受到强烈的冲击或震动,以免损坏本设备及其配件,导致本设备故障。 <br>清洁和维护前,请停止使用本设备,关闭所有应用,拿出电池。 <br>请勿使用烈性化学制品、清洗剂或强洗涤剂清洁本设备或其配件。请使用清洁、干燥的软布擦拭本设备或其配件。 <br>请勿擅自拆卸或改装本设备及配件,否则本设备及配件将不在本公司保修范围之内。 <br>本设备发生故障时请联系授权服务中心。  ",
          en:
            "Please keep the device and its accessories dry, do not use external heating devices such as microwave ovens or hair dryers to dry them. <br>Do not subject the device and its accessories to strong shocks or vibrations, so as not to damage the device and its accessories and cause the device to malfunction. <br>Before cleaning and maintenance, please stop using this device, close all applications, and take out the battery from the device. <br>Do not use strong chemicals, cleaning agents, or strong detergents to clean the device or its accessories. Please use a clean, dry soft cloth to wipe the device or its accessories. <br>Do not disassemble or modify the device and accessories without authorization, otherwise the device and accessories will not be covered by the warranty. <br>Please contact the customer service  when the device encounters any failures. ",
        },
      },
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
  },
};
</script>

<style scoped>
.maintenanceOuter {
  text-align: left;
  color: #424242;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
img {
  max-width: 140px;
  margin-right: 30px;
}
.title {
  width: auto;
  flex-grow: 2;
}
.title-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 100px;
}
.subtitle {
  margin-right: 100px;
}
.text {
  width: 100%;
  max-width: 800px;
  font-size: 0.8rem;
  margin-top: 30px;
  margin-bottom: 50px;
}
.text p {
  line-height: 1.5rem;
}
.image {
  display: flex;
  justify-content: center;
  align-items: start;
}
.warranty {
  background-color: #377267;
  color: white;
  text-align: center;
  height: 1.5rem;
  width: 70px;
  border-radius: 20px;
}
.warranty p {
  margin: 0.1rem;
  font-size: 0.8rem;
}

@media (max-width: 850px) {
  img {
    max-width: 80px;
    margin-right: 20px;
  }
  .maintenanceOuter {
    padding-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }
  h1 {
    font-size: 1.2rem;
    min-width: 8rem;
  }
  .subtitle p {
    font-size: 0.7rem;
  }
  .subtitle {
    margin-right: 0px;
  }
  .text {
    font-size: 0.7rem;
  }
  .text p {
    line-height: 1rem;
  }
  .warranty {
    display: none;
  }
}
</style>