var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer"},[(!_vm.isMobile)?_c('img',{staticStyle:{"width":"100%","position":"absolute","top":"0px","left":"0px","z-index":"0"},attrs:{"src":require("../assets/images/company profile-06.png"),"alt":"top gradient"}}):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"extra-padding",staticStyle:{"padding-top":"80px"}}):_vm._e(),_c('div',{staticClass:"flex-center-column"},[_c('h2',{staticClass:"shoptitle",domProps:{"innerHTML":_vm._s(_vm.isEnglish ? _vm.h1.en : _vm.h1.cn)}}),_c('div',{staticClass:"tmall"},[_c('a',{attrs:{"href":_vm.isMobile
            ? 'https://shop320849369.m.taobao.com'
            : 'http://osheepps.tmall.com/index.htm',"target":"_blank"}},[_vm._m(0)]),_c('p',[_vm._v(_vm._s(_vm.isEnglish ? _vm.tmallSubtitle.en : _vm.tmallSubtitle.cn))])])]),_c('hr'),_c('div',{staticClass:"socialMedia"},[_c('h2',[_vm._v(_vm._s(_vm.isEnglish ? _vm.h2.en : _vm.h2.cn))]),_c('div',{ref:"button1",staticClass:"smbuttons"},_vm._l((_vm.buttons),function(button,index){return _c('a',{key:index,staticStyle:{"position":"relative"},attrs:{"href":button.url,"target":button.url == '#' ? '' : '_blank'},on:{"mouseover":function($event){button.ishover = !_vm.isMobile},"mouseleave":function($event){button.ishover = _vm.isMobile},"click":function($event){button.ishover = _vm.isMobile}}},[_c('transition',{attrs:{"name":"bounce"}},[(button.name == 'wechat' && button.ishover && !_vm.isMobile)?_c('span',{staticClass:"tooltiptext",staticStyle:{"width":"300px","height":"120px"}},[_c('div',{staticClass:"flex-center-row"},[_c('img',{attrs:{"src":require("../assets/images/qr-code.png")}}),_c('div',[_c('p',{staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.wechatPopup.title.en : _vm.wechatPopup.title.cn)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.wechatPopup.subtitle.en : _vm.wechatPopup.subtitle.cn)+" ")])])])]):_vm._e(),(
              button.name == 'wechat mini program' &&
              button.ishover &&
              !_vm.isMobile
            )?_c('span',{staticClass:"tooltiptext",staticStyle:{"width":"300px","height":"240px"}},[_c('div',{staticClass:"flex-center-row"},[_c('img',{attrs:{"src":require("../assets/images/miniprogram1.png")}}),_c('div',[_c('p',{staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.miniprogramPopup.program1.title.en : _vm.miniprogramPopup.program1.title.cn)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.miniprogramPopup.program1.subtitle.en : _vm.miniprogramPopup.program1.subtitle.cn)+" ")])])]),_c('hr'),_c('div',{staticClass:"flex-center-row"},[_c('img',{attrs:{"src":require("../assets/images/miniprogram2.png")}}),_c('div',[_c('p',{staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.miniprogramPopup.program2.title.en : _vm.miniprogramPopup.program2.title.cn)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.miniprogramPopup.program2.subtitle.en : _vm.miniprogramPopup.program2.subtitle.cn)+" ")])])])]):_vm._e()]),_c('img',{attrs:{"src":button.ishover ? button.hover : button.reg,"alt":button.alt}})],1)}),0),_c('p',{staticStyle:{"padding-bottom":"50px"}},[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.socialMediaSubtitle.en : _vm.socialMediaSubtitle.cn)+" ")])]),_c('transition',{attrs:{"name":"bounce"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.buttons[0].ishover && _vm.isMobile),expression:"buttons[0].ishover && isMobile"},{name:"closable",rawName:"v-closable",value:({
        exclude: ['button', 'button1'],
        handler: 'onClose',
      }),expression:"{\n        exclude: ['button', 'button1'],\n        handler: 'onClose',\n      }"}],staticClass:"popup"},[_c('div',{staticClass:"popup-inner"},[_c('button',{ref:"button",staticClass:"x-button",on:{"click":function($event){_vm.buttons[0].ishover = false}}},[_c('svg',{staticClass:"bi bi-x",attrs:{"width":"1em","height":"1em","viewBox":"0 0 16 16","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"}})])]),_c('div',{directives:[{name:"hammer",rawName:"v-hammer:swipe.vertical",value:(_vm.onSwipe),expression:"onSwipe",arg:"swipe",modifiers:{"vertical":true}}],staticClass:"popup-inner-inner",style:({
            top: _vm.popupScrolledDown ? '-120px' : '0px',
          })},[_c('div',{staticClass:"flex-center-row popup-section",staticStyle:{"margin-right":"-10px"}},[_c('img',{staticClass:"qr",attrs:{"src":require("../assets/images/miniprogram1.png")}}),_c('div',[_c('p',{staticClass:"popup-header"},[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.miniprogramPopup.program1.title.en : _vm.miniprogramPopup.program1.title.cn)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.miniprogramPopup.program1.subtitle.en : _vm.miniprogramPopup.program1.subtitle.cn)+" ")])])]),_c('div',{staticClass:"flex-center-row",staticStyle:{"height":"10px","justify-content":"center"},on:{"click":function($event){_vm.popupScrolledDown = !_vm.popupScrolledDown;
              _vm.buttons[0].ishover = true;}}},[(!_vm.popupScrolledDown)?_c('img',{staticClass:"popup-arrow",attrs:{"src":require("../assets/images/carousel-control-down.png")}}):_c('img',{staticClass:"popup-arrow",attrs:{"src":require("../assets/images/carousel-control-up.png")}})]),_c('div',{staticClass:"flex-center-row popup-section"},[_c('img',{staticClass:"qr",attrs:{"src":require("../assets/images/miniprogram2.png")}}),_c('div',[_c('p',{staticClass:"popup-header"},[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.miniprogramPopup.program2.title.en : _vm.miniprogramPopup.program2.title.cn)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.isEnglish ? _vm.miniprogramPopup.program2.subtitle.en : _vm.miniprogramPopup.program2.subtitle.cn)+" ")])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tmall-inner"},[_c('img',{attrs:{"src":require("../assets/images/tmall.png"),"alt":"tmall"}})])}]

export { render, staticRenderFns }