// Container class for all of the pages in mobile. Contains header, selectedScroll component, and copyright
<template>
  <div class="scroll-outer">
    <!-- Header containing nav burger and icon -->
    <header :class="{ backdropfiltered: selectedScroll != 'cover' }">
      <img
        :src="
          selectedScroll == 'cover'
            ? require('../assets/images/mobile-burger-white.png')
            : require('../assets/images/mobile-burger.png')
        "
        alt="open navigation"
        @click="openNav"
      />
      <h3
        class="logo"
        :style="{ color: selectedScroll == 'cover' ? 'white' : '#377267' }"
      >
        <i class="icon-logo-text" />
      </h3>
      <span class="header-placeholder"></span>
    </header>
    <!-- padding -->
    <div v-if="selectedScroll != 'cover'" style="height: 90px"></div>
    <!-- selectedScroll component -->
    <component :is="selectedScroll" :isMobile="isMobile"></component>
    <!-- padding -->
    <div v-if="selectedScroll == 'cover'" style="height: 100vh"></div>
    <!-- bottom copyright/icp -->
    <div class="copyright">
      <a
        class="bottomlink"
        :style="{ color: selectedScroll == 'cover' ? 'white' : '#377267' }"
        href="http://beian.miit.gov.cn/"
        >© O!sheepps 2020. 苏ICP备19065148号-2</a
      >
    </div>
  </div>
</template>

<script>
import Cover from "./Cover.vue";
import MobileEmoware from "./EmowareComponents/MobileEmoware.vue";
import AboutUs from "./AboutUs.vue";
import Shop from "./Shop.vue";
import PrivacyPolicy from "./PrivacyPolicy.vue";
import LicensePolicy from "./LicensePolicy.vue";
import ContactUs from "./ContactUs";
import $ from "jquery";

export default {
  data() {
    return {
      isMobile: true,
    };
  },
  computed: {
    selectedScroll() {
      if (this.$store.getters.selectedScroll == "") {
        return "cover";
      }
      return this.$store.getters.selectedScroll;
    },
  },
  methods: {
    openNav() {
      $("html,body").animate({ scrollTop: 0 }, 0);
      this.$emit("openNav");
    },
  },
  components: {
    Cover,
    AboutUs,
    Shop,
    MobileEmoware,
    PrivacyPolicy,
    LicensePolicy,
    ContactUs,
  },
};
</script>

<style scoped>
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "yang" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-logo-text:before {
  content: "\e901";
}
.logo {
  font-family: "BrownRegular", "ArialRoundedBold", Helvetica, Arial,
    "sans-serif" !important;
  font-size: 55px;
  color: #377267;
  padding-top: 15px;
}
.logo h3 {
  margin-bottom: 0px;
}
.scroll-outer {
  width: 100%;
  background-color: white;
  overflow: scroll;
  display: inline-block;
  position: relative;
}
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0px;
  padding-left: 25px;
  padding-right: 25px;
  position: fixed;
  width: 100%;
  z-index: 201;
  height: 80px;
  top: 0;
}

.backdropfiltered {
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid #f1f4f5;
}

header img {
  padding-top: 30px;
  height: 50px;
  width: 25px;
}

.header-placeholder {
  width: 25px;
}

header h3 {
  width: 130px;
}

.copyright {
  position: relative;
  width: 100%;
  bottom: 25px;
  text-align: center;
  height: 0px;
  line-height: 0;
  padding: 0px;
  z-index: 201;
}
.bottomlink {
  font-size: 0.6rem;
}
</style>