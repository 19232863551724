// Landing page on web, cycles through pictures and contains web nav
<template>
  <div class="cover-container" v-hammer:swipe.vertical="onSwipe">
    <transition name="slide-fade">
      <main
        role="main"
        class="inner cover down"
        v-bind:style="{
          'background-image': 'url(' + imageURLs[currID] + ')'
        }"
        v-bind:key="currID"
      ></main>
    </transition>
    <app-nav v-if="!isMobile" @goDown="goDown" @goUp="goUp"></app-nav>
  </div>
</template>

<script>
import Nav from "./Nav.vue";

export default {
  props: {
    isMobile: Boolean
  },
  data() {
    return {
      currID: 0,
      imageURLs: [
        window.innerHeight > window.innerWidth
          ? require("../assets/images/mobile-t1.jpg")
          : require("../assets/images/t1.jpg"),
        require("../assets/images/t2.jpg"),
        require("../assets/images/t3.jpg")
      ],
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    };
  },
  components: {
    appNav: Nav
  },
  methods: {
    // swipe moves cover only if width small enough so that there is no button
    onSwipe() {
      if (!this.isDown && window.innerWidth < 580 && !this.isMobile) {
        this.goUp();
      } else if (this.isDown && window.innerWidth < 580 && !this.isMobile) {
        this.goDown(50);
      }
    },
    changeCover() {
      this.currID++;
      this.currID = this.currID % this.imageURLs.length;
    },
    goDown(height) {
      this.$emit("goDown", height, true);
      this.$store.commit("setIsDown", false);
    },
    goUp() {
      this.$emit("goUp");
      this.$store.commit("setIsDown", true);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    }
  },
  created() {
    this.interval = setInterval(() => this.changeCover(), 5000);
  },
  watch: {
    windowHeight(newH) {
      this.imageURLs[0] =
        newH > window.innerWidth
          ? require("../assets/images/mobile-t1.jpg")
          : require("../assets/images/t1.jpg");
    },
    windowWidth(newW) {
      this.imageURLs[0] =
        window.innerHeight > newW
          ? require("../assets/images/mobile-t1.jpg")
          : require("../assets/images/t1.jpg");
    }
  },
  computed: {
    isDown() {
      return this.$store.getters.isDown;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cover-container {
  width: 100%;
  height: 100%;
  background: #ccc;
  position: fixed;
  z-index: 200;
  top: 0;
}
main.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 10;
  -webkit-transition: all 5s ease;
  transition: all 5s ease;
}

.slide-fade-enter-active {
  opacity: 1;
  z-index: 10;
}

.slide-fade-leave-active {
  opacity: 1;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
