// Component for displaying the License information in footer for web or separate page for mobile
<template>
  <!-- change background color depending on mobile or web -->
  <div
    class="policy-outer"
    v-bind:style="{
      'background-color': isMobile ? 'white' : '#f2f2f2'
    }"
  >
    <!-- add header if mobile -->
    <h3 v-if="isMobile">
      {{
        isEnglish
          ? "SOFTWARE LICENSE & SERVICE AGREEMENT"
          : "软件许可及服务协议"
      }}
    </h3>
    <!-- pass in license data to policy component -->
    <app-policy :policy="license"> </app-policy>
  </div>
</template>

<script>
import Policy from "./Policy.vue";

export default {
  props: {
    isMobile: Boolean
  },
  components: {
    appPolicy: Policy
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
    license() {
      return this.$store.state.license;
    }
  }
};
</script>

<style scoped>
.policy-outer h3 {
  text-align: center;
  font-size: 1.5rem;
}
</style>