// Styling class for any policy, takes in policy object for content
<template>
  <div class="footer-content">
    <!-- policy description -->
    <p v-html="isEnglish ? policy.desc.en.content : policy.desc.cn.content"></p>
    <!-- policy sections -->
    <div
      v-for="(section, index) in policy.item.filter(
        (s) => s.isEnglish == isEnglish
      )"
      :key="index"
      class="item"
    >
      <div class="footer-header" @click="section.isActive = !section.isActive">
        <h3>{{ section.title }}</h3>
        <div class="item-button">
          <img
            v-if="!section.isActive"
            class="down"
            src="../assets/images/footer-item-down.png"
            alt="expand section"
          />
          <img
            v-else
            class="up"
            src="../assets/images/footer-item-up.png"
            alt="shrink section"
          />
        </div>
      </div>
      <transition name="slide">
        <div
          v-if="section.isActive"
          v-html="section.content"
          class="item-content"
        ></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    policy: null,
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
  },
};
</script>

<style>
.footer-content {
  font-size: 13px;
  padding-left: 10%;
  padding-right: 10%;
  min-height: calc(100vh - 200px);
}
.item {
  position: relative;
}
.item h3 {
  font-size: 1rem;
  padding: 30px 0;
}
.item-button {
  padding: 20px;
}
.item-button.active .down,
.item-button .up {
  display: block;
}
.item-button.active .up {
  display: block;
}
.item-button img {
  width: 32px;
}
.footer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
  cursor: pointer;
}
.item-content {
  display: block;
  line-height: 24px;
}
.slide-enter-active {
  -moz-transition-duration: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 2000px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

@media (max-width: 650px) {
  .footer-content {
    padding: 25px;
  }
}
</style>