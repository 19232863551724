// Navigation page for mobile, contains links to each page, language change, copyright
<template>
  <div>
    <nav class="nav" id="nav">
      <div class="nav-top">
        <!-- links to each page -->
        <div class="nav-text">
          <div
            v-for="(label, index) in labels"
            :key="index"
            @click="selectScroll(label)"
          >
            <p>
              <nobr>{{ isEnglish ? label.enName : label.cnName }}</nobr>
            </p>
          </div>
        </div>
        <!-- close nav button -->
        <img
          class="x-button"
          src="../assets/images/mobile-x.png"
          alt="close navigation"
          @click="close"
        />
      </div>
      <div class="nav-bottom">
        <!-- lang change -->
        <div class="lang-button">
          <button :class="{ active: !isEnglish }" @click="changeLang">
            中
          </button>
          <button :class="{ active: isEnglish }" @click="changeLang">EN</button>
        </div>
        <!-- copyright/icp -->
        <div class="copyright-icp">
          <a class="copyright">© O!sheepps 2020. </a>
          <a class="icp" href="http://beian.miit.gov.cn/" target="_blank"
            >苏ICP备19065148号-2</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labels: [
        {
          enName: "Home",
          cnName: "主页",
          component: "cover",
        },
        {
          enName: "Emoware",
          cnName: "云朵随心秤",
          component: "mobileEmoware",
        },
        {
          enName: "Shop/Follow Us",
          cnName: "购买",
          component: "shop",
        },
        {
          enName: "About Us",
          cnName: "关于我们",
          component: "aboutUs",
        },
        {
          enName: "Privacy Policy",
          cnName: "隐私政策",
          component: "privacyPolicy",
        },
        {
          enName: "Software/License Agreement",
          cnName: "软件许可及服务协议",
          component: "licensePolicy",
        },
        {
          enName: "Contact Us",
          cnName: "联系我们",
          component: "contactUs",
        },
      ],
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
  },
  methods: {
    selectScroll(label) {
      this.$store.commit("selectScroll", label.component);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    changeLang() {
      this.$store.commit("selectScroll", "cover");
      this.$emit("close");
      this.$store.commit("switchLang");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  background-color: #377267;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 30px;
  padding-bottom: 20px;
  color: #b0dbd0;
  width: calc(101vw + 10px);
  height: calc(101vh + 10px);
  margin-left: -10px;
  margin-top: -10px;
  overflow: scroll;
}
p {
  border-bottom: 1px solid #b0dbd0;
  display: inline-block;
}
.nav-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.nav-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.nav-text {
  padding-top: 10px;
  display: inline-block;
}
.x-button {
  width: 20px;
}
.lang-button {
  min-width: 48px;
}
.lang-button button {
  margin-right: 10px;
  font-size: 0.75rem;
  background-color: inherit;
  color: inherit;
  border: inherit;
  padding: 0px;
  padding-bottom: 2px;
  cursor: pointer;
}
.lang-button .active {
  border-bottom: 1px solid #b0dbd0;
}
.copyright-icp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
}
.copyright {
  color: #b0dbd0;
  font-size: 0.75rem;
}
.icp {
  color: #b0dbd0;
  font-size: 0.75rem;
  margin-left: 5px;
}
</style>
