// Contains detailed information about products, 3rd section on Emoware page
<template>
  <div class="detailsOuter row">
    <div class="detailsInner">
      <!-- first three blocks -->
      <div v-for="(block, index) in blocks" :key="index">
        <div v-for="(section, i) in block" :key="i" class="row">
          <div class="col-md-2 col-sm-3 col-4">
            <p>{{ isEnglish ? section.h.en : section.h.cn }}</p>
          </div>
          <div class="col-md-10 col-sm-9 col-8">
            <p>{{ isEnglish ? section.p.en : section.p.cn }}</p>
          </div>
        </div>
        <hr />
      </div>
      <!-- last block, special formatting -->
      <div class="row">
        <div class="col-md-2 col-sm-3 col-4">
          <p>{{ isEnglish ? lastBlock.h1.en : lastBlock.h1.cn }}</p>
        </div>
        <div class="col-md-10 col-sm-9 col-8">
          <img
            class="bluetooth"
            src="../../assets/images/pc_web-17.png"
            alt="bluetooth"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 col-sm-3 col-4">
          <p>{{ isEnglish ? lastBlock.h2.en : lastBlock.h2.cn }}</p>
        </div>
        <div class="col-md-10 col-sm-9 col-8">
          <p>{{ isEnglish ? lastBlock.p2.en : lastBlock.p2.cn }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blocks: [
        [
          {
            h: { cn: "产品名称", en: "Product Name" },
            p: { cn: "云朵随心秤cloudia", en: "Cloudia: Cloud-shaped scale" },
          },
          {
            h: { cn: "产品型号", en: "Product Model" },
            p: {
              cn: "Cloudia S1",
              en: "Cloudia S1",
            },
          },
          {
            h: { cn: "外壳材料", en: "Material" },
            p: { cn: "ABS", en: "ABS" },
          },
        ],
        [
          {
            h: { cn: "产品尺寸", en: "Product Dimensions" },
            p: {
              cn: "365×315×26mm    |      72×60×27mm",
              en: "365×315×26mm    |      72×60×27mm",
            },
          },
          {
            h: { cn: "工作温度", en: "Working Temperature" },
            p: {
              cn: "-10 - 40°C",
              en: "-10 - 40°C",
            },
          },
          {
            h: { cn: "产品净重", en: "Product Weight" },
            p: { cn: "1.7kg", en: "1.7kg" },
          },
          {
            h: { cn: "电源", en: "Power Supply" },
            p: { cn: "7节15V7号(AAA) 电池", en: "7 15V(AAA) Batteries" },
          },
        ],
        [
          {
            h: { cn: "称量范围", en: "Weight Range" },
            p: {
              cn: "2.5kg- 100kg",
              en: "2.5kg- 100kg",
            },
          },
          {
            h: { cn: "称重单位", en: "Weighing Units" },
            p: {
              cn: "公斤/斤/磅",
              en: "kg/斤/lb",
            },
          },
          {
            h: { cn: "产品净重", en: "Product Weight" },
            p: { cn: "1.7kg", en: "1.7kg" },
          },
          {
            h: { cn: "最小分度", en: "Minimum Gradation" },
            p: { cn: "50g", en: "50g" },
          },
        ],
      ],
      lastBlock: {
        h1: { cn: "无线连接", en: "Wireless Connection" },
        h2: { cn: "硬件要求", en: "Hardware Requirements" },
        p2: {
          cn: "支持可以使用微信小程序的移动端",
          en:
            "Only supports mobile terminals that can use WeChat Mini Programs",
        },
      },
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
  },
};
</script>

<style scoped>
.detailsOuter {
  text-align: left;
  color: #424242;
  font-size: 12px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detailsInner {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
}
p {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.bluetooth {
  height: 2rem;
}
@media (max-width: 850px) {
  p {
    font-size: 0.7rem;
  }
  .detailsOuter {
    padding-bottom: 50px;
  }
  .detailsOuter {
    margin-left: 15px;
    margin-right: 15px;
  }
}
</style>