// Display products at the top of Emoware page for Mobile
<template>
  <div>
    <b-carousel
      id="carousel-banner"
      v-model="slide"
      :interval="0"
      controls
      background="white"
      img-width="100%"
      img-height="auto"
    >
      <b-carousel-slide
        v-for="(slide, index) in slides"
        :key="index"
        :img-src="slide.img"
        :alt="'product ' + slide.name.en"
      >
        <h3>S1</h3>
        <p>{{ isEnglish ? slide.name.en : slide.name.cn }}</p>
        <div class="color-bluetooth">
          <div :style="{ backgroundColor: slide.color }" class="color"></div>
          <div class="bluetooth"></div>
        </div>
        <div class="textsouter">
          <p>
            {{ isEnglish ? slide.message.en : slide.message.cn }}
          </p>
          <p
            class="bannertext"
            v-html="isEnglish ? slide.text.en : slide.text.cn"
          ></p>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slides: [
        {
          img: require("../../assets/images/mobile-prod1.png"),
          name: { en: "「Pink Lady」", cn: "「大蜜橘」" },
          text: {
            en:
              "Choose her like you would a best friend. <br>Warm and caring, <nobr>listen to her whisper secrets to you.</nobr>",
            cn:
              "选择大蜜橘就像选择自己的闺蜜。<br>够温暖，也够贴心。<br><nobr>快点凑近吧，</nobr><nobr>看看大蜜橘和你说了哪些悄悄话。</nobr>",
          },
          color: "#f3a8a1",
          message: {
            en: "￥ 800",
            cn: "￥ 800",
          },
        },
        {
          img: require("../../assets/images/mobile-prod2.png"),
          name: { en: "「Basic Edition」", cn: "「灰执事」" },
          text: {
            en:
              "A steward for your heath, <nobr>reassuring you whenever you need it. </nobr><br>Calm, composed, and always honest.",
            cn:
              "<nobr>让灰执事作为你的健康管家，</nobr><nobr>是件很安心事儿。</nobr><br>低调、沉着、不慌张，<nobr>也从不向你说谎。</nobr>",
          },
          color: "#9c9ea0",
          message: {
            en: "￥ 800",
            cn: "￥ 800",
          },
        },
        {
          img: require("../../assets/images/mobile-prod3.png"),
          name: { en: "「Boyfriend」", cn: "「蓝朋友」" },
          text: {
            en:
              "Your ideal boyfriend: not very talkative, <nobr>but always gentle. </nobr><br>Whether you are large or small, <nobr>this blue is reliable and inclusive.</nobr>",
            cn:
              "蓝朋友的蓝，就像理想中的男朋友。<br>或许不怎么爱说话，却很温柔。<br>无论你是胖是瘦，都蓝的很稳，爱的很包容。",
          },
          color: "#547199",
          message: {
            en: "￥ 800",
            cn: "￥ 800",
          },
        },
      ],
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
  },
};
</script>

<style>
#carousel-banner .carousel-control-prev-icon {
  background-image: url("../../assets/images/carousel-control-prev.png") !important;
}
#carousel-banner .carousel-control-next-icon {
  background-image: url("../../assets/images/carousel-control-next.png") !important;
}
#carousel-banner .carousel-control-prev-icon,
#carousel-banner .carousel-control-next-icon {
  width: 10px !important;
  height: 30px !important;
  margin-bottom: 150px !important;
}
#carousel-banner .carousel-control-prev,
#carousel-banner .carousel-control-next {
  opacity: 1 !important;
}
#carousel-banner .carousel-caption {
  color: #377267 !important;
  bottom: calc(-280px + 10vw) !important;
}
#carousel-banner .carousel-inner {
  padding-bottom: 250px !important;
}
#carousel-banner .carousel-caption h3 {
  font-size: 1.25rem;
  margin-bottom: 0.1rem;
}
#carousel-banner .carousel-caption p {
  font-size: 0.8rem;
}
</style>

<style scoped>
.color-bluetooth {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 20px;
  margin-bottom: 20px;
}
.color {
  height: 20px;
  width: 55px;
  border-radius: 10px;
  margin-right: 10px;
  border: 2px solid #b0dbd0;
}
.bluetooth {
  height: 20px;
  width: 80px;
  background-image: url("../../assets/images/mobile-bluetooth.png");
  background-size: auto 11px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 2px solid #b0dbd0;
}
.textsouter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.bannertext {
  height: 100px;
  font-size: 0.7rem;
  max-width: 270px;
  width: 92vw;
}
</style>