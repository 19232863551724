// Container class for all of the components in Emoware page on web
<template>
  <div class="outer">
    <img
      style="width: 100%; position: absolute; top: 0px; left: 0px; z-index: 0"
      src="../../assets/images/company profile-06.png"
      alt="top gradient"
    />
    <banner></banner>
    <product></product>
    <prod-details></prod-details>
    <prod-maintenance></prod-maintenance>
    <tmall-banner></tmall-banner>
  </div>
</template>

<script>
import Banner from "./Banner.vue";
import Product from "./Product.vue";
import ProdDetails from "./ProdDetails.vue";
import ProdMaintenance from "./ProdMaintenance.vue";
import TmallBanner from "./TmallBanner.vue";

export default {
  components: {
    Banner,
    Product,
    ProdDetails,
    ProdMaintenance,
    TmallBanner
  }
};
</script>

<style scoped>
.outer {
  color: black;
  background: white;
  min-height: calc(100vh - 210px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-x: hidden;
}
</style>