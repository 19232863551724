// Footer component for web. Contains lang change, contact, policies, copyright
<template>
  <div>
    <footer class="footer" id="footer">
      <!-- lang change -->
      <a class="footer-btn-lang lang-big" @click="changeLang">
        <span :class="{ active: isEnglish }" val="en">EN</span>
        <br />
        <span :class="{ active: !isEnglish }" val="cn">CN</span>
      </a>
      <a class="footer-btn-lang lang-small" @click="changeLang">
        <span :class="{ active: isEnglish }" val="en">EN</span> /
        <span :class="{ active: !isEnglish }" val="cn">CN</span>
      </a>

      <!-- contact/policies buttons -->
      <a
        class="footer-btn"
        v-for="(label, index) in labels"
        :key="index"
        :class="{ grow: label.grow }"
        @click="selectFooter(label)"
        >{{ isEnglish ? label.enName : label.cnName }}</a
      >

      <!-- copyright/icp -->
      <a class="copyright"
        >© O!sheepps 2020.
        <a class="icp" href="http://beian.miit.gov.cn/" target="_blank"
          >苏ICP备19065148号-2</a
        >
      </a>
    </footer>
    <!-- expanding dynamic components for contact info and policies -->
    <transition name="slide">
      <div
        v-show="!dataAllInactive && chosenFooter != ''"
        style="position: relative"
      >
        <transition name="slide-horiz">
          <component :is="chosenFooter" style="width: 100vw"></component>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import $ from "jquery";
import PrivacyPolicy from "./PrivacyPolicy.vue";
import LicensePolicy from "./LicensePolicy.vue";
import BrandPolicy from "./BrandPolicy.vue";
import ContactUs from "./ContactUs.vue";

export default {
  props: { allInactive: Boolean },
  data() {
    return {
      labels: [
        {
          enName: "BRAND",
          cnName: "品牌介绍",
          componentName: "appBrandPolicy",
          grow: false,
        },
        {
          enName: "CONTACT US",
          cnName: "联系我们",
          componentName: "appContactUs",
          grow: false,
        },
        {
          enName: "PRIVACY POLICY",
          cnName: "隐私政策",
          componentName: "appPrivacyPolicy",
          grow: false,
        },
        {
          enName: "SOFTWARE LICENSE & SERVICE AGREEMENT",
          cnName: "软件许可及服务协议",
          componentName: "appLicensePolicy",
          grow: true,
        },
      ],
      license: null,
      privacy: null,
      chosenFooter: "",
      dataAllInactive: this.allInactive,
      email:
        "<a href='mailto:" +
        "info" +
        "@" +
        "o-sheepps.com" +
        "'>" +
        "info" +
        "@" +
        "o-sheepps.com" +
        "</a>",
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    },
    selectedScroll() {
      return this.$store.getters.selectedScroll;
    },
  },
  methods: {
    // a footer link has been clicked
    selectFooter(selectedLabel) {
      let scroll_top = $("#footer").offset().top - 100;
      if (
        this.chosenFooter == selectedLabel.componentName &&
        this.dataAllInactive == false
      ) {
        // We need to unselect the label
        this.dataAllInactive = true;
        if (this.selectedScroll == "") {
          // move the cover back down
          this.$emit("goDown", 100, true);
        }
      } else {
        // select the label
        this.dataAllInactive = false;
        this.chosenFooter = selectedLabel.componentName;
        this.$emit("selectedFooter");
        if (this.selectedScroll == "") {
          // move the cover up
          this.$emit("goDown", $(window).height() - 100, true);
        } else {
          // scroll up so that we can see the section
          $("html,body").animate({ scrollTop: scroll_top }, 1000);
        }
      }
    },
    changeLang() {
      this.$store.commit("selectScroll", "");
      this.$emit("goUp");
      this.$store.commit("switchLang");
    },
  },
  components: {
    appPrivacyPolicy: PrivacyPolicy,
    appLicensePolicy: LicensePolicy,
    appBrandPolicy: BrandPolicy,
    appContactUs: ContactUs,
  },
  watch: {
    allInactive: function (newVal) {
      this.dataAllInactive = newVal;
    },
  },
};
</script>

<style scoped>
footer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.footer {
  border-top: 0.5px solid #377267;
  background-color: #f2f2f2;
  padding-left: 90px;
  min-height: 102px;
}
.grow {
  flex-grow: 2;
}
.footer-content {
  padding-left: 10%;
  padding-right: 10%;
  width: 100vw;
}
.footer-btn-lang .logo {
  font-family: "BrownRegular", "ArialRoundedBold", Helvetica, Arial,
    "sans-serif";
}
.lang-big {
  display: block;
}
.lang-small {
  display: none;
}
footer {
  background: #fff;
  padding: 0 60px;
}
footer a {
  display: inline-block;
  line-height: 40px;
  padding: 25px 0;
  margin-right: 100px;
  cursor: pointer;
  color: #377267;
  width: auto;
  font-size: 0.9rem;
  text-decoration: none;
  user-select: none;
  line-height: 1.3;
}
.footer-btn-lang {
  color: #b0dbd0;
}
footer a:hover {
  text-decoration: none;
  color: #333;
}

footer a.copyright {
  float: right;
  width: auto;
  text-align: right;
  cursor: default;
  margin-right: 0;
  font-family: "BrownRegular", "ArialRoundedBold", Helvetica, Arial,
    "sans-serif";
  font-size: 0.8rem;
}
footer a.copyright:hover {
  color: #377267;
}
footer a span {
  display: inline-block;
  padding-bottom: 5px;
}
footer a span.active {
  color: #377267;
}
footer a.icp {
  padding: 0;
  margin-right: 0px;
  font-size: 0.8rem;
}
.footer-content {
  background-color: #f2f2f2;
  padding-bottom: 1rem;
}
@media (max-width: 1300px) {
  footer a {
    margin-right: 40px;
  }
}
@media (max-width: 900px) {
  .footer-btn {
    display: none;
  }
  .footer {
    padding-left: 30px;
    padding-right: 30px;
    min-height: 50px;
    justify-content: space-between;
  }
  .lang-big {
    display: none;
  }
  .lang-small {
    display: block;
    flex-grow: 2;
  }
  footer a {
    padding: 0;
    margin-right: 0px;
    flex-shrink: 1;
    min-width: 60px;
  }
  footer a.copyright {
    flex-shrink: 2;
  }
}
.slide-enter-active {
  -moz-transition-duration: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 1.5s;
  -webkit-transition-duration: 1.5s;
  -o-transition-duration: 1.5s;
  transition-duration: 1.5s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-enter-to,
.slide-leave {
  max-height: 2000px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.slide-horiz-enter-active {
  transition: all 0.8s ease;
}
.slide-horiz-leave-active {
  transition: all 0.8s ease;
}
.slide-horiz-enter {
  transform: translateX(-100vw);
  position: absolute;
  top: 0px;
}

.slide-horiz-leave-to {
  transform: translateX(100vw);
  position: absolute;
  top: 0px;
}
</style>