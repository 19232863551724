// Error page that displays a message
<template>
  <div>
    <img src="../assets/images/oops.png" alt="error" />
    <p v-if="!isEnglish">{{ message.cn }}</p>
    <p v-else>{{ message.en }}</p>
  </div>
</template>

<script>
export default {
  props: {
    message: Object
  },
  computed: {
    isEnglish() {
      return this.$store.getters.isEnglish;
    }
  }
};
</script>

<style scoped>
div {
  background-color: #b0dbd0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
img {
  width: 10%;
  margin-bottom: 10px;
}
</style>